<template>
  <select class="custom-select" :class="{'border-danger': hasError}" :value="value" @input="handleChange">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import { orderBy } from 'lodash-es'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  computed: {
    options () {
      const categories = [
        {
          value: 'art_culture',
          label: 'Arts et culture'
        },
        {
          value: 'electro',
          label: 'Électronique'
        },
        {
          value: 'fashion',
          label: 'Mode et vêtements'
        },
        {
          value: 'food_beverage',
          label: 'Nourriture et boissons'
        },
        {
          value: 'home_deco',
          label: 'Maison et décoration'
        },
        {
          value: 'hardware_reno',
          label: 'Quincaillerie et rénovations'
        },
        {
          value: 'health_beauty',
          label: 'Santé et beauté'
        },
        {
          value: 'sport_recreation',
          label: 'Sports et loisirs'
        },
        {
          value: 'cars',
          label: 'Automobile'
        },
        {
          value: 'pets',
          label: 'Animaux'
        }
      ]

      const sortedCategories = orderBy(categories, ['label'], ['asc'])

      return [
        ...sortedCategories,
        {
          value: 'other',
          label: 'Autre'
        }
      ]
    }
  }
}
</script>
